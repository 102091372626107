import React from 'react';


const Foreground = () => {
  return (
    <svg id="visual" viewBox="0 0 1920 1080" width="1920" height="1080" version="1.1">
        <defs>
            <linearGradient id="gradient2" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#5e0878" />
            <stop offset="80%" stopColor="#1a0d2f" />
            </linearGradient>
        </defs>
        <path d="M0 504L18.8 536C37.7 568 75.3 632 113 654C150.7 676 188.3 656 226 682.2C263.7 708.3 301.3 780.7 339 795.5C376.7 810.3 414.3 767.7 452 740.5C489.7 713.3 527.3 701.7 565 688.8C602.7 676 640.3 662 678 678C715.7 694 753.3 740 791 718.2C828.7 696.3 866.3 606.7 903.8 560.5C941.3 514.3 978.7 511.7 1016.2 553C1053.7 594.3 1091.3 679.7 1129 705.5C1166.7 731.3 1204.3 697.7 1242 711.5C1279.7 725.3 1317.3 786.7 1355 774.3C1392.7 762 1430.3 676 1468 653.5C1505.7 631 1543.3 672 1581 680.5C1618.7 689 1656.3 665 1694 621.3C1731.7 577.7 1769.3 514.3 1807 495.5C1844.7 476.7 1882.3 502.3 1901.2 515.2L1920 528L1920 1081L1901.2 1081C1882.3 1081 1844.7 1081 1807 1081C1769.3 1081 1731.7 1081 1694 1081C1656.3 1081 1618.7 1081 1581 1081C1543.3 1081 1505.7 1081 1468 1081C1430.3 1081 1392.7 1081 1355 1081C1317.3 1081 1279.7 1081 1242 1081C1204.3 1081 1166.7 1081 1129 1081C1091.3 1081 1053.7 1081 1016.2 1081C978.7 1081 941.3 1081 903.8 1081C866.3 1081 828.7 1081 791 1081C753.3 1081 715.7 1081 678 1081C640.3 1081 602.7 1081 565 1081C527.3 1081 489.7 1081 452 1081C414.3 1081 376.7 1081 339 1081C301.3 1081 263.7 1081 226 1081C188.3 1081 150.7 1081 113 1081C75.3 1081 37.7 1081 18.8 1081L0 1081Z" fill="url(#gradient2)" strokeLinecap="round" strokeLinejoin="miter">
        </path>
    </svg>
  );
};

export default Foreground;