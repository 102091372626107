import React from 'react';

const Background = () => {
  return (
    <svg id="visual" viewBox="0 0 1920 1080" width="1920" height="1080" version="1.1">
        <defs>
            <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#310f38" />
            <stop offset="100%" stopColor="#020024" />
            </linearGradient>
        </defs>
        <path d="M0 706L17.8 663C35.7 620 71.3 534 106.8 477C142.3 420 177.7 392 213.2 364.2C248.7 336.3 284.3 308.7 320 332.5C355.7 356.3 391.3 431.7 426.8 431.7C462.3 431.7 497.7 356.3 533.2 390.8C568.7 425.3 604.3 569.7 640 583.8C675.7 598 711.3 482 746.8 494.5C782.3 507 817.7 648 853.2 697.2C888.7 746.3 924.3 703.7 960 617.7C995.7 531.7 1031.3 402.3 1066.8 341.3C1102.3 280.3 1137.7 287.7 1173.2 327.3C1208.7 367 1244.3 439 1280 460.3C1315.7 481.7 1351.3 452.3 1386.8 469.2C1422.3 486 1457.7 549 1493.2 552.3C1528.7 555.7 1564.3 499.3 1600 451.2C1635.7 403 1671.3 363 1706.8 361.8C1742.3 360.7 1777.7 398.3 1813.2 458.3C1848.7 518.3 1884.3 600.7 1902.2 641.8L1920 683L1920 1081L1902.2 1081C1884.3 1081 1848.7 1081 1813.2 1081C1777.7 1081 1742.3 1081 1706.8 1081C1671.3 1081 1635.7 1081 1600 1081C1564.3 1081 1528.7 1081 1493.2 1081C1457.7 1081 1422.3 1081 1386.8 1081C1351.3 1081 1315.7 1081 1280 1081C1244.3 1081 1208.7 1081 1173.2 1081C1137.7 1081 1102.3 1081 1066.8 1081C1031.3 1081 995.7 1081 960 1081C924.3 1081 888.7 1081 853.2 1081C817.7 1081 782.3 1081 746.8 1081C711.3 1081 675.7 1081 640 1081C604.3 1081 568.7 1081 533.2 1081C497.7 1081 462.3 1081 426.8 1081C391.3 1081 355.7 1081 320 1081C284.3 1081 248.7 1081 213.2 1081C177.7 1081 142.3 1081 106.8 1081C71.3 1081 35.7 1081 17.8 1081L0 1081Z" fill="url(#gradient)" strokeLinecap="round" strokeLinejoin="miter"></path>
    </svg>
  );
};

export default Background;
